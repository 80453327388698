import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/plugins/animation.css';
import './assets/css/plugins/feature.css';
import './assets/css/plugins/magnify.min.css';
import './assets/css/plugins/slick.css';
import './assets/css/plugins/slick-theme.css';
import './assets/css/plugins/lightbox.css';
import './assets/css/style.css';
 

function App() {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
